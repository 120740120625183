export const theme = {
  screen: {
    headerHeight: 100,
    footerHeight: 100,
  },

  color: {
    theme: `hsl(0, 0%, 96%)`,

    while: `white`,
    backgroundWhite: `hsl(0, 0%, 96%)`,

    black: `black`,
    regularBlack: `hsl(0, 0%, 13%)`,

    subTextGrey: `hsl(0, 0%, 46%)`,
  },

  font: {
    weight: {
      '300': 300,
      '900': 900,
    },
    family: {
      sanSerif: `'Rubik', 'Helvetica', 'Hiragino Sans', 'Meiryo', sans-serif`,
    },
  },
}
