/** 1. Imports **/
import React from 'react'
import styled, { withTheme } from 'styled-components'

import { Header } from './header'
import { Footer } from './footer'
import { theme } from '../styles/theme'

/** 2. Types **/
type Props = {
  className?: string
  children: React.ReactNode
}

/** 3. Base component **/
const Component = ({ className, children }: Props) => (
  <>
    <Header />
    <main className={className}>{children}</main>
    <Footer />
  </>
)

/** 4. Styled component **/
export const Layout = styled(Component)`
  display: grid;
  justify-content: center;
  align-content: center;
  
  min-height: calc(100vh - ${theme.screen.headerHeight}px - ${theme.screen.footerHeight}px);
`
